import Tag from './Tag';

document.addEventListener('DOMContentLoaded', () => {
  const $tags = document.querySelectorAll('.js-tag');
  if (!$tags.length) {
    return;
  }
  $tags.forEach(($tag) => {
    const tag = new Tag($tag);
    const handler = (tag) => {
      tag.off('click', handler);
      tag.delete();
    };
    tag.on('click', handler);
    tag.mount();
  });
});
