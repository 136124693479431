document.addEventListener('DOMContentLoaded', function() {
    const dateElements = document.querySelectorAll('.js-date');

    const checkPosition = () => {
        dateElements.forEach(element => {

            const rect = element.getBoundingClientRect();


            const halfwayPoint = window.innerHeight / 2;


            if (rect.top <= halfwayPoint && rect.bottom >= halfwayPoint) {
                element.classList.add('active');
            }
        });
    };


    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', checkPosition);


    checkPosition();
});