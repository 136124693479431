import Details from './Details';

document.addEventListener('DOMContentLoaded', () => {
  const $detailsElements = document.querySelectorAll('.js-details');
  if (!$detailsElements.length) {
    return;
  }
  $detailsElements.forEach(($details) => {
    const details = new Details($details, {
      hasAdditionalControl: true,
    });
    details.mount();
  });
});
