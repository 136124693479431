import Breadcrumbs from './Breadcrumbs';

document.addEventListener('DOMContentLoaded', () => {
  const $breadcrumbsElements = document.querySelectorAll('.js-breadcrumbs');
  if (!$breadcrumbsElements.length) {
    return;
  }
  $breadcrumbsElements.forEach(($breadcrumbs) => {
    const breadcrumbs = new Breadcrumbs($breadcrumbs, {
      isResponsive: true,
    });
    breadcrumbs.mount();
  });
});
