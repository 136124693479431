import Swiper from 'swiper';
import {Navigation, EffectFade, Autoplay} from 'swiper/modules';

// Slider Component

document.addEventListener('DOMContentLoaded', () => {
  const sliderComp = document.querySelector('.js-slider');

  if (sliderComp) {
    const swiper = new Swiper(sliderComp, {
      modules: [Navigation],
      navigation: {
        nextEl: '.js-slider-next',
        prevEl: '.js-slider-prev'
      },
    });
  }
});  

// Home hero

document.addEventListener('DOMContentLoaded', () => {
  const sliderHome = document.querySelector('.js-slider-home');

  if (sliderHome) {
    const swiper = new Swiper(sliderHome, {
      modules: [Navigation, EffectFade, Autoplay],
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,  
      },
      speed: 1000, 
      autoplay: {
        delay: 6000, 
        disableOnInteraction: false,
      },
      loop: true,
    });
  }
});  

// Home job

document.addEventListener('DOMContentLoaded', () => {
  const sliderHomeJob = document.querySelector('.js-slider-home-job');

  if (sliderHomeJob) {
    const swiper = new Swiper(sliderHomeJob, {
      modules: [Navigation, EffectFade, Autoplay],
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,  
      },
      speed: 1000, 
      autoplay: {
        delay: 6000, 
      },
      loop: true,
    });
  }
});  


// Companies

document.addEventListener('DOMContentLoaded', () => {
  const sliderCompaniesContainer = document.querySelector('.js-companies');
  const sliderCompanies = document.querySelector('.js-slider-companies');

  if (sliderCompanies && sliderCompaniesContainer) {
    let swiper;
  
    const initSwiper = () => {
      swiper = new Swiper(sliderCompanies, {
        modules: [Autoplay, Navigation],
        spaceBetween: 65,
        centeredSlides: true,
        speed: 3000,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        loop: true,
        slidesPerView: 'auto',
        allowTouchMove: true, 
        navigation: {
          nextEl: '.js-companies-next',
          prevEl: '.js-companies-prev',
        },
      });
    };
  
    
    initSwiper();
  
    
    sliderCompaniesContainer.addEventListener('mouseenter', () => {
      swiper.autoplay.stop();
      swiper.params.speed = 600;
    });
  
    
    sliderCompaniesContainer.addEventListener('mouseleave', () => {
      swiper.params.speed = 3000;
      swiper.autoplay.start();
    });
  }
});