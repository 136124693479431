/**
 * Name: Tag
 * Description: Actionnable tag
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Tag extends Component {
  static settings = {};

  #handleClick;

  constructor (element, options = {}) {
    super(element, {
      ...Tag.settings,
      ...options
    });

    this.#handleClick = this._handleClick.bind(this);
  }

  _handleClick () {
    this.fire('click', this);
  }

  delete () {
    this.unmount();
    this.element.remove();
    this.fire('delete', this);
  }

  mount () {
    this.element.addEventListener('click', this.#handleClick);

    super.mount();
  }

  unmount () {
    this.element.removeEventListener('click', this.#handleClick);

    super.unmount();
  }
}
